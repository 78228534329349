import { gsap } from 'gsap';
import lottie from 'lottie-web';
import { inview } from '../../utils/store';
import json from '../../assets/json/book.json';

export default class Services {
  constructor() {
    this.el = document.querySelector('.s-services');

    this.services = {
      book: this.el.querySelector('.services-book'),
    }

    this.state = {
      inview: false,
    }
  }

  init() {
    gsap.ticker.add(this.render);

    const options = {
      loop: false,
      autoplay: false,
      renderer: 'svg',
      animationData: json,
      container: this.services.book,
    };

    this.animation = lottie.loadAnimation(options);
    // this.animation.setSpeed(10);
    this.animation.onComplete = this.onComplete;
  }

  onComplete = () => {
    this.animation.goToAndPlay(150, true);
  }

  render = () => {
    if (inview(this.services.book, 2)) {
      if (this.state.inview === true) return;
      this.state.inview = true;
      this.animation.play();
    } else {
      if (this.state.inview === false) return;
      this.state.inview = false;
      this.animation.pause();
    }
  }
}
