export const app = {
  nav: undefined,
  cta: undefined,
  loading: undefined,
  header: undefined,
  hello: undefined,
  stories: undefined,
  effects: undefined,
  about: undefined,
  scroll: undefined,
  scene: undefined,
  ipad: false,
};

export const math = {
  lerp: (a, b, n) => (1 - n) * a + n * b,
  progress: (min, max, value) => value / (max - min) + min,
  clamp: (number, min, max) => Math.max(min, Math.min(number, max)),
  collision(a, b) {
    return !(
      ((a.getBoundingClientRect().top + a.getBoundingClientRect().height) < (b.getBoundingClientRect().top)) ||
      (a.getBoundingClientRect().top > (b.getBoundingClientRect().top + b.getBoundingClientRect().height)) ||
      ((a.getBoundingClientRect().left + a.getBoundingClientRect().width) < b.getBoundingClientRect().left) ||
      (a.getBoundingClientRect().left > (b.getBoundingClientRect().left + b.getBoundingClientRect().width))
    );
  }
};

export const inview = (e, offset = 1) => {
  const { top, bottom } = e.getBoundingClientRect();
  return top < window.innerHeight / offset && bottom >= 0;
};

export const pager = (e = 1) => {
  const { top, bottom } = e.getBoundingClientRect();
  return top < window.innerHeight / 2 && bottom >= window.innerHeight / 2;
};

export const random = (e) => {
  return Math.floor(Math.random() * Math.floor(e));
}

export const wrap = (e) => {
  e.style.display = '';
  e.style.position = '';
  const d = document.createElement('div');
  d.classList.add('f-line-parent');
  e.parentNode.insertBefore(d, e);
  d.appendChild(e);
}

export function throttle(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
