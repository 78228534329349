import Sniffer from 'sniffer';
import Matter from 'matter-js';
import { throttle } from '../../utils/store';

export default class Scene {
  constructor() {
    this.el = document.getElementById('a-scene');
    this.visible = false;
    this.timeout = undefined;
  }

  init() {
    this.run();
    window.addEventListener('resize', throttle(this.resize, 66), false);
  }

  enter() {
    if (this.visible) return;

    this.visible = true;
    this.Runner.run(this.runner, this.engine);

    // add roof later because of animation
    if (this.timeout) { clearInterval(this.timeout) };

    this.timeout = setInterval(() => {
      clearInterval(this.timeout);
      this.Composite.add(this.world, this.Bodies.rectangle(window.innerWidth / 2, -26, window.innerWidth, 50, { isStatic: true }));
    }, 2000);
  }

  events() {
    this.el.addEventListener('mouseup', this.mouseUp);
    this.el.addEventListener('mousedown', this.mouseDown);
  }

  mouseDown = () => {
    this.el.querySelector('canvas').style.cursor = 'grabbing';
  }

  mouseUp = () => {
    this.el.querySelector('canvas').style.cursor = 'grab';
  }

  resize = () => {
    if (!this.render.canvas) return;

    this.visible = false;

    this.Render.stop(this.render);
    this.World.clear(this.world);
    this.Engine.clear(this.engine);

    this.render.canvas.remove();
    this.render.canvas = null;
    this.render.context = null;

    this.el.removeEventListener('mouseup', this.mouseUp);
    this.el.removeEventListener('mousedown', this.mouseDown);

    this.run();
    this.enter();
  }

  run() {
    // chrome only!
    if (Sniffer.isDevice || Sniffer.isSafari || Sniffer.isFirefox) return;

    // events
    this.events();

    // globals
    const windowWidth = window.innerWidth;
    const windowHeight = document.querySelector('.s-contact').getBoundingClientRect().height;

    // engine
    this.Engine = Matter.Engine;
    this.Render = Matter.Render;
    this.Runner = Matter.Runner;
    this.MouseConstraint = Matter.MouseConstraint;
    this.Mouse = Matter.Mouse;
    this.Composite = Matter.Composite;
    this.Bodies = Matter.Bodies;
    this.World = Matter.World;

    // create engine
    this.engine = this.Engine.create();

    this.world = this.engine.world;

    // create renderer
    this.render = this.Render.create({
      element: this.el,
      engine: this.engine,
      options: {
        showStats: false,
        wireframes: false,
        background: 'none',
        width: windowWidth,
        height: windowHeight,
      }
    });

    this.Render.run(this.render);

    // create runner
    this.runner = this.Runner.create();

    // add bodies
    const options = {
      isStatic: true
    };

    // world
    this.Composite.add(this.world, [

      // copyright
      this.Bodies.circle(windowWidth * 0.52, -windowHeight / 3, windowWidth * 0.02, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/copyright.svg',
            xScale: windowWidth * 0.00035,
            yScale: windowWidth * 0.00035,
          }
        }
      }
      ),

      // trademark
      this.Bodies.circle(windowWidth * 0.19, -windowHeight / 1.25, windowWidth * 0.035, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/trademark.svg',
            xScale: windowWidth * 0.00045,
            yScale: windowWidth * 0.00045,
          }
        }
      }
      ),

      // yellow square
      this.Bodies.rectangle(windowWidth * 0.32, -windowHeight / 1.5, windowWidth * 0.06, windowWidth * 0.06, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/square.svg',
            xScale: windowWidth * 0.0004,
            yScale: windowWidth * 0.0004,
          }
        }
      }
      ),

      // dots square
      this.Bodies.rectangle(windowWidth * 0.224, -windowHeight / 1.9, windowWidth * 0.06, windowWidth * 0.06, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/square-dots.svg',
            xScale: windowWidth * 0.0004,
            yScale: windowWidth * 0.0004,
          }
        }
      }
      ),

      // amper
      this.Bodies.rectangle(windowWidth * 0.44, -windowHeight / 1.3, windowWidth * 0.08, windowWidth * 0.1, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/amper.svg',
            xScale: windowWidth * 0.00065,
            yScale: windowWidth * 0.00065,
          }
        }
      }
      ),

      // exclamation
      this.Bodies.rectangle(windowWidth * 0.5354, windowHeight, windowWidth * 0.02, windowWidth * 0.1, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/exclamation.svg',
            xScale: windowWidth * 0.00065,
            yScale: windowWidth * 0.00065,
          }
        }
      }
      ),

      // crosses
      this.Bodies.rectangle(windowWidth * 0.25, windowHeight, windowWidth * 0.1, windowWidth * 0.05, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/crosses.svg',
            xScale: windowWidth * 0.00035,
            yScale: windowWidth * 0.00035,
          }
        }
      }
      ),

      // tilde
      this.Bodies.rectangle(windowWidth * 0.1, windowHeight, windowWidth * 0.1, windowWidth * 0.02, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/tilde.svg',
            xScale: windowWidth * 0.0003,
            yScale: windowWidth * 0.0003,
          }
        }
      }
      ),

      // paragraph
      this.Bodies.rectangle(windowWidth * 0.05, windowHeight, windowWidth * 0.06, windowWidth * 0.15, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/paragraph.svg',
            xScale: windowWidth * 0.0011,
            yScale: windowWidth * 0.0011,
          }
        }
      }
      ),

      // bracket
      this.Bodies.rectangle(windowWidth * 0.3, -windowHeight, windowWidth * 0.02, windowWidth * 0.1, {
        render: {
          sprite: {
            texture: 'https://glossarie.xyz/wp-content/themes/glossarie/src/assets/svg/bracket.svg',
            xScale: windowWidth * 0.00065,
            yScale: windowWidth * 0.00065,
          }
        }
      }
      ),

      // floor
      this.Bodies.rectangle(windowWidth / 2, windowHeight + 26, windowWidth, 50, options),

      // walls
      this.Bodies.rectangle(-26, windowHeight / 2, 50, windowHeight, options),
      this.Bodies.rectangle(windowWidth + 26, windowHeight / 2, 50, windowHeight, options),
    ]);

    // add mouse control
    this.mouse = this.Mouse.create(this.render.canvas);
    this.mouseConstraint = this.MouseConstraint.create(this.engine, {
      mouse: this.mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false
        }
      }
    });

    this.Composite.add(this.world, this.mouseConstraint);

    // keep the mouse in sync with rendering
    this.render.mouse = this.mouse;

    // fit the render viewport to the scene
    this.Render.lookAt(this.render, {
      min: { x: 0, y: 0 },
      max: { x: windowWidth, y: windowHeight }
    });
  }
}
