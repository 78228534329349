import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import Sniffer from 'sniffer';
import { inview } from './store';

export default class Effects {
  constructor() {
    this.animate = {
      word: document.querySelectorAll('[data-animate="word"] > *'),
      fadeIn: document.querySelectorAll('[data-animate="fade-in"]'),
    }

    this.parallax = document.querySelectorAll('[data-parallax]');

    this.state = {
      inview: false,
    }
  }

  init() {
    document.fonts.ready.then(this.set);
  }

  set = () => {
    // words
    const splitWords = new SplitText(this.animate.word, {
      type: 'words',
      wordsClass: 'f-word',
    });

    gsap.set(splitWords.words, {
      autoAlpha: 0,
      y: 50,
      willChange: 'transform',
    });

    // fade
    gsap.set(this.animate.fadeIn, {
      autoAlpha: 0,
      y: 50,
      willChange: 'transform',
    });

    // render
    gsap.ticker.add(this.render);
  }

  render = () => {
    // ofset
    let offset;

    Sniffer.isPhone ? offset = 1 : offset = 1.2;

    // parallax
    this.parallax.forEach((el) => {
      const y = el.parentNode.getBoundingClientRect().top;
      const s = el.dataset.speed;
      const r = el.dataset.rotate;

      if (inview(el.parentNode)) {
        el.style.transform = `translate3d(0px, ${y / s}px, 0px) rotate(${y / r}deg)`;
      }
    });

    // words
    this.animate.word.forEach((e) => {
      if (inview(e, offset) && (!e.inview === true)) {
        const stagger = e.closest('[data-animate="word"]').dataset.stagger;
        e.inview = true;
        gsap.to(
          e.querySelectorAll('.f-word'),
          { y: 0, autoAlpha: 1, duration: 1.5, stagger, ease: 'power4.out', clearProps: 'will-change' }
        );
      }
    });

    // fade
    this.animate.fadeIn.forEach((e) => {
      if (inview(e, offset) && (!e.inview === true)) {
        const delay = e.dataset.delay;
        e.inview = true;
        gsap.to(
          e,
          { y: 0, autoAlpha: 1, delay, duration: 1.5, ease: 'power4.out', clearProps: 'will-change' }
        );
      }
    });
  }
}
