import Sniffer from 'sniffer';
import { app } from '../../utils/store';
import { gsap } from 'gsap';

export default class Cta {
  constructor() {
    this.mX = 0;
    this.mY = 0;

    this.cX = 0;
    this.cY = 0;

    this.el = document.getElementById('a-cta');

    this.cta = {
      circle: this.el.querySelector('.circle'),
      active: this.el.querySelector('.active'),
    }

    this.button = document.querySelectorAll('.f-button');
  }

  events() {
    this.button.forEach((el) => {
      el.addEventListener('click', this.onClick);
    });

    this.el.addEventListener('mouseenter', this.mouseEnter);
    this.el.addEventListener('mouseleave', this.mouseLeave);

    window.addEventListener('mousemove', (e) => {
      this.mX = e.clientX - window.innerWidth * 0.91;
      this.mY = e.clientY - window.innerWidth * 0.08;
    });

    gsap.ticker.add(this.render);
  }

  onClick() {
    const bottom = document.getElementById('a-app').offsetHeight - window.innerHeight;

    if (Sniffer.isDevice) {
      window.scrollTo(0, bottom);
    } else {
      app.scroll.data.ease = 0.07;
      app.scroll.position(bottom);
    }
  }

  mouseEnter = () => {
    gsap.to(this.el, {
      color: '#ffffff',
    });

    gsap.to(this.cta.active, {
      scale: 2,
      duration: 2,
      ease: 'power4.out',
    });
  }

  mouseLeave = () => {
    gsap.to(this.el, {
      color: '#413780',
    });

    gsap.to(this.cta.active, {
      scale: 0,
      duration: 2,
      ease: 'power4.out',
    });
  }

  render = () => {
    this.cX += ((this.mX - this.cX) * 0.14);
    this.cY += ((this.mY - this.cY) * 0.14);

    this.cta.circle.style.transform = `translate3d(${this.cX}px, ${this.cY}px, 0)`;
  }

  enter = () => {
    let delay;
    Sniffer.isPhone ? delay = 3.5 : delay = 2.5;

    gsap.fromTo(
      this.button,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1.5, delay }
    );
  }

  init() {
    this.events();
    this.mouseLeave();
  }
}
