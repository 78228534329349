import gsap from "gsap/gsap-core";

export default class Header {
  constructor() {
    this.el = document.getElementById('a-header');

    this.header = {
      logo: this.el.querySelector('.header-logo'),
    }

    this.logo = {
      chars: this.header.logo.querySelectorAll('path'),
      star: this.header.logo.querySelector('.svg-star')
    }
  }

  enter = () => {
    gsap.fromTo(
      this.logo.chars,
      { yPercent: 125 },
      { yPercent: 0, duration: 2, stagger: 0.1, ease: 'power4.inOut' },
    );

    const bounds = this.header.logo.getBoundingClientRect().height / 2;
    const y = window.innerHeight / 2 - bounds - window.innerWidth * 0.04167;

    gsap.fromTo(
      this.header.logo,
      { y },
      { y: 0, delay: 2, duration: 2, ease: 'power4.inOut' },
    );

    gsap.fromTo(
      this.logo.star,
      { autoAlpha: 0 },
      { autoAlpha: 1, delay: 1, duration: 1, ease: 'power4.inOut' },
    );

    gsap.to(
      this.logo.star,
      { rotate: 360, repeat: -1, delay: 2, duration: 5, ease: 'linear' },
    );

    gsap.set(
      this.header.logo,
      { delay: 0.2, visibility: 'inherit' }
    )

    gsap.set(
      this.header.logo,
      { delay: 3, pointerEvents: 'inherit', }
    )
  }
}
