import { gsap } from 'gsap';
import { inview } from '../../utils/store';

export default class Stories {
  constructor() {
    this.dom = {
      el: document.querySelector('.s-stories'),
      nav: document.querySelectorAll('.svg-arrow'),
      slide: document.querySelectorAll('.stories-slide'),
      current: document.querySelector('.stories-current'),
      indicator: document.querySelector('.stories-indicator'),
    }

    this.options = {
      speed: 1,
      ease: 'cubic.inOut',
    }

    this.state = {
      current: 0,
      animating: false,
    }
  }

  init() {
    this.addEvents();

    gsap.ticker.add(this.render);
  }

  render = () => {
    if (inview(this.dom.el, 2)) {
      gsap.ticker.remove(this.render);
      this.enter();
    }
  }

  enter() {
    gsap.fromTo(
      [
        this.dom.nav,
        this.dom.indicator,
      ],
      { autoAlpha: 0, },
      { autoAlpha: 1, duration: 1, stagger: 0.1 }
    )
    gsap.fromTo(
      this.dom.slide[0].children,
      { y: 50, autoAlpha: 0, },
      { y: 0, autoAlpha: 1, duration: 1.5, stagger: 0.1, ease: 'power4.out', willChange: 'transform' }
    )
  }

  addEvents() {
    this.dom.nav[1].addEventListener('click', this.animateNext);
    this.dom.nav[1].addEventListener('mouseenter', this.mouseEnter);
    this.dom.nav[1].addEventListener('mouseleave', this.mouseLeave);

    this.dom.nav[0].addEventListener('click', this.animatePrev);
    this.dom.nav[0].addEventListener('mouseenter', this.mouseEnter);
    this.dom.nav[0].addEventListener('mouseleave', this.mouseLeave);
  }

  mouseEnter = (e) => {
    const circle = e.target.querySelector('.fill');
    const arrow = e.target.querySelectorAll('path');
    const path = 311;

    gsap.fromTo(
      arrow[0],
      { xPercent: 0 },
      { xPercent: -250, duration: 1, ease: 'power4.out' },
    );

    gsap.fromTo(
      arrow[1],
      { xPercent: 250 },
      { xPercent: 0, duration: 1, ease: 'power4.out' },
    );

    gsap.fromTo(
      circle,
      {
        rotate: 0,
        strokeDasharray: path,
        strokeDashoffset: path,
        transformOrigin: '50% 50%',
      },
      {
        rotate: 180,
        duration: 0.8,
        ease: 'power4.out',
        strokeDashoffset: 0,
        transformOrigin: '50% 50%',
      },
    );
  }

  mouseLeave = (e) => {
    const circle = e.target.querySelector('.fill');
    const path = 311;

    gsap.to(
      circle,
      {
        rotate: 0,
        duration: 0.8,
        ease: 'power4.out',
        strokeDashoffset: path,
      },
    );
  }

  animatePrev = () => {
    if (this.state.animating === true) return;
    this.state.animating = true;

    gsap.to(
      this.dom.slide[this.state.current].children,
      { y: 50, autoAlpha: 0, duration: 0.8, stagger: -0.1, ease: 'power4.in', willChange: 'transform' }
    );

    gsap.to(
      this.dom.current.querySelector('span'),
      { yPercent: 100, autoAlpha: 0, duration: 0.8, ease: 'power4.in' }
    );

    if (this.state.current === 0) {
      this.state.current = this.dom.slide.length - 1;
    } else {
      this.state.current--;
    }

    gsap.fromTo(
      this.dom.slide[this.state.current].children,
      { y: -50, },
      {
        y: 0, autoAlpha: 1, delay: 0.8, duration: 0.8, stagger: -0.1, ease: 'power4.out', willChange: 'transform',
        onComplete: () => {
          this.state.animating = false;
        }
      }
    );

    this.animateCurrent(-100);
  }

  animateNext = () => {
    if (this.state.animating === true) return;
    this.state.animating = true;

    gsap.to(
      this.dom.slide[this.state.current].children,
      { y: -50, autoAlpha: 0, duration: 0.8, stagger: 0.1, ease: 'power4.in', willChange: 'transform' }
    );

    gsap.to(
      this.dom.current.querySelector('span'),
      { yPercent: -100, autoAlpha: 0, duration: 0.8, ease: 'power4.in' }
    );

    if (this.state.current === this.dom.slide.length - 1) {
      this.state.current = 0;
    } else {
      this.state.current++;
    }

    gsap.fromTo(
      this.dom.slide[this.state.current].children,
      { y: 50, },
      {
        y: 0, autoAlpha: 1, delay: 0.8, duration: 0.8, stagger: 0.1, ease: 'power4.out', willChange: 'transform',
        onComplete: () => {
          this.state.animating = false;
        }
      }
    );

    this.animateCurrent(100);
  }

  animateCurrent = (value) => {
    const span = document.createElement('span');

    span.innerHTML = this.state.current + 1;

    gsap.set(span, { yPercent: value, autoAlpha: 0 });

    this.dom.current.appendChild(span);

    gsap.to(span, {
      yPercent: 0, autoAlpha: 1, duration: 0.8, delay: 0.8, ease: 'power4.out',
      onComplete: () => {
        this.dom.current.querySelector('span').remove();
      }
    });
  }
}
