import { gsap } from 'gsap';
import lottie from 'lottie-web';
import { inview } from '../../utils/store';
import json from '../../assets/json/about.json';

export default class About {
  constructor() {
    this.el = document.querySelector('.s-about');

    this.about = {
      symbols: this.el.querySelector('.about-symbols'),
    }

    this.state = {
      inview: false,
    }
  }

  init() {
    gsap.ticker.add(this.render);

    const options = {
      autoplay: false,
      renderer: 'svg',
      animationData: json,
      container: this.about.symbols,
    };

    this.animation = lottie.loadAnimation(options);
  }

  render = () => {
    if (inview(this.about.symbols)) {
      if (this.state.inview === true) return;
      this.state.inview = true;
      this.animation.play();
    } else {
      if (this.state.inview === false) return;
      this.state.inview = false;
      this.animation.pause();
    }
  }
}
