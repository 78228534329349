import Sniffer from 'sniffer';
import { gsap } from 'gsap';
import { inview, app } from '../../utils/store';

export default class Contact {
  constructor() {
    this.el = document.querySelector('.s-contact');
    this.scroll = document.querySelector('.contact-scroll');
  }

  events() {
    this.scroll.addEventListener('click', this.onClick);
    this.scroll.addEventListener('mouseenter', this.mouseEnter);
    this.scroll.addEventListener('mouseleave', this.mouseLeave);
  }

  mouseEnter = (e) => {
    const circle = e.target.querySelector('.fill');
    const arrow = e.target.querySelectorAll('path');
    const path = 311;

    gsap.fromTo(
      arrow[0],
      { yPercent: 0 },
      { yPercent: -250, duration: 1, ease: 'power4.out' },
    );

    gsap.fromTo(
      arrow[1],
      { yPercent: 250 },
      { yPercent: 0, duration: 1, ease: 'power4.out' },
    );

    gsap.fromTo(
      circle,
      {
        rotate: 90,
        strokeDasharray: path,
        strokeDashoffset: path,
        transformOrigin: '50% 50%',
      },
      {
        rotate: 270,
        duration: 1.5,
        ease: 'power4.out',
        strokeDashoffset: 0,
        transformOrigin: '50% 50%',
      },
    );
  }

  mouseLeave = (e) => {
    const circle = e.target.querySelector('.fill');
    const path = 311;

    gsap.to(
      circle,
      {
        rotate: 90,
        duration: 1.5,
        ease: 'power4.out',
        strokeDashoffset: path,
      },
    );
  }

  onClick() {
    if (Sniffer.isDevice) {
      app.hello.reset();
      app.hello.rotate.disabled = true;
      window.scrollTo(0, 0);
      gsap.delayedCall(1, () => {
        app.hello.rotate.disabled = false
      });
    } else {
      app.scroll.data.ease = 0.07;
      app.scroll.position(0);
    }
  }

  render = () => {
    if (inview(this.el)) {
      gsap.ticker.remove(this.render);
      app.scene.enter();
    }
  }

  init() {
    this.events();

    if (Sniffer.isDevice) return;

    gsap.ticker.add(this.render);
  }
}
