import gsap from 'gsap/gsap-core';
import Sniffer from 'sniffer';
import { app, pager, math, throttle } from '../../utils/store';

export default class Nav {
  constructor() {
    this.el = document.getElementById('a-nav');

    this.nav = {
      buttons: this.el.querySelectorAll('.f-nav'),
      active: this.el.querySelector('.nav-active'),
      li: this.el.querySelectorAll('.nav-li'),
    }

    this.sections = document.querySelectorAll('[data-section]');

    this.state = {
      active: 0,
      scrolling: false,
    }

    this.progress = 0;
  }

  init() {
    if (Sniffer.isDevice) return;

    this.nav.buttons.forEach((e) => {
      e.addEventListener('click', this.onClick);
    });

    this.setAnimation();

    gsap.ticker.add(this.render);

    window.addEventListener('resize', throttle(this.setAnimation, 66), false);

    this.nav.li[0].classList.add('is-active');
  }

  render = () => {
    this.getActive();

    const current = Math.abs(app.scroll.data.last);

    const min = 0;
    const max = window.innerHeight * 2;

    this.progress = math.progress(min, max, current);

    this.tl.progress(this.progress);
  }

  setAnimation = () => {
    this.tl = gsap.timeline({ paused: true });

    this.tl.fromTo(this.el, {
      y: window.innerHeight - this.el.getBoundingClientRect().height - window.innerWidth * 0.035,
    }, {
      y: window.innerWidth * 0.035, ease: 'power4.out',
    });
  }

  onClick = (e) => {
    let offset = 0;

    app.scroll.data.ease = 0.07;

    const nav = e.target.dataset.nav;
    const section = document.querySelector(`[data-section="${nav}"]`);

    if (nav === 'services') {
      offset = section.offsetTop + window.innerWidth * 0.2;
    }

    if (nav === 'stories') {
      offset = section.offsetTop + (section.getBoundingClientRect().height - window.innerHeight) / 2;
    }

    if (nav === 'about') {
      offset = section.offsetTop - window.innerWidth * 0.05;
    }

    if (nav === 'contact') {
      offset = document.getElementById('a-app').getBoundingClientRect().height - window.innerHeight;
    }

    app.scroll.position(offset);
    this.setActive(e.target);
  }

  setActive = (e) => {
    const color = e.dataset.color;
    const y = e.getBoundingClientRect().top - this.el.getBoundingClientRect().top;

    this.state.scrolling = true;

    gsap.killTweensOf(this.nav.active);
    gsap.killTweensOf(this.nav.active.querySelector('path'));

    this.el.style.setProperty('--color-active', color);

    gsap.to(
      this.nav.active,
      { y, duration: 1, ease: 'power4.out' }
    );

    gsap.to(
      this.nav.active.querySelector('path'),
      { fill: color, duration: 1, onComplete: () => { this.state.scrolling = false; } }
    );

    this.nav.li[this.state.active].classList.add('is-active');
  }

  getActive = () => {
    if (this.state.scrolling === true || app.loading.state === true) return;

    this.nav.li.forEach((el, i) => {
      if (i === this.state.active) return;
      el.classList.remove('is-active');
    });

    this.sections.forEach((el, i) => {
      if (pager(el)) {
        if (i === this.state.active) return;
        this.state.active = i;
        this.setActive(this.nav.buttons[this.state.active]);
      }
    });
  }

  enter = () => {
    gsap.fromTo(
      this.nav.buttons,
      { yPercent: 125, autoAlpha: 1 },
      { yPercent: 0, duration: 1.5, delay: 2.5, stagger: 0.25, ease: 'power4.out', willChange: 'transform', onComplete: () => { gsap.set(this.nav.li, { overflow: 'inherit' }) } }
    );

    gsap.fromTo(
      this.nav.active,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1.5, delay: 2.5, ease: 'power4.out' }
    );

    gsap.to(
      this.nav.active.querySelector('svg'),
      { rotate: 360, duration: 5, repeat: -1, ease: 'linear' }
    );
  }
}
