// styles
import './index.scss';

// modules
import Sniffer from 'sniffer';

// store
import { app } from './utils/store';

// utils
import Loading from './utils/loading';
import Scroll from './utils/scroll';

// components
import Cta from './components/cta/cta';
import Header from './components/header/header';
import About from './components/about/about';
import Hello from './components/hello/hello';
import Nav from './components/nav/nav';
import Services from './components/services/services';
import Stories from './components/stories/stories';
import Contact from './components/contact/contact';
import Scene from './components/scene/scene';

// effects
import Effects from './utils/effects';

// sniffer
Sniffer.addClasses(document.documentElement);

// ios fix
if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
  document.querySelector('html').classList.add('is-tablet');
  document.querySelector('html').classList.remove('is-desktop');
  app.ipad = true;
  if (Sniffer.isPhone) {
    document.querySelector('html').classList.remove('is-tablet');
  }
}

// service worker
const sw = `${location.origin}/sw.js`;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(sw).then(registration => {
  }).catch(error => {
    console.log(error);
  });
}

// init
app.scroll = new Scroll();
app.scroll.init();

app.header = new Header();

app.cta = new Cta();
app.cta.init();

app.about = new About();
app.about.init();

app.hello = new Hello();
app.hello.init();

app.nav = new Nav();
app.nav.init();

app.services = new Services();
app.services.init();

app.stories = new Stories();
app.stories.init();

app.contact = new Contact();
app.contact.init();

app.effects = new Effects();
app.effects.init();

app.scene = new Scene();
app.scene.init();

// loading
app.loading = new Loading();
app.loading.init();
