import { app } from './store';
import Sniffer from 'sniffer';

export default class Loading {
  constructor() {
    this.state = true;
  }

  init() {
    app.nav.enter();
    app.header.enter();
    app.cta.enter();
    app.hello.enter();

    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      app.loading.state = false;
      document.body.style.overflow = '';
      if (Sniffer.isDevice) return;
      app.scroll.resize();
    }, 3500);
  }
}
