import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';
import { app, math, throttle } from './store';

export default class Scroll {
  constructor() {
    this.vs = new VirtualScroll();

    this.scroll = document.getElementById('a-app');

    this.data = {
      last: 0,
      speed: 5,
      ease: 0.15,
      current: 0,
      // direction: 0,
    };

    this.hud = {
      nav: document.querySelectorAll('.f-nav'),
      light: document.querySelector('[data-ui-light]'),
    }
  }

  render = () => {
    if (app.loading.state === true) return;

    if (Sniffer.isDevice) {
      this.data.current = Math.abs(document.body.getBoundingClientRect().top);
    }

    this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease);
    this.data.abs = Math.abs(this.data.last);

    if (this.data.abs < 0.1) {
      this.data.last = 0;
    }

    if (Sniffer.isDevice) return;

    this.transform();
    this.contrast();
  }

  contrast() {
    this.hud.nav.forEach((e) => {
      if (math.collision(e, this.hud.light)) {
        e.classList.add('ui-light');
      } else {
        e.classList.remove('ui-light');
      }
    });
  }

  transform() {
    this.scroll.style.transform = `translate3d(0px, ${this.data.last}px, 0px)`;
  }

  calc = (e) => {
    if (app.loading.state === true) return;

    this.data.current += e.deltaY / this.data.speed;
    this.data.current = Math.max((this.bounding.height - window.innerHeight) * -1, this.data.current);
    this.data.current = Math.min(0, this.data.current);

    // if (this.data.current > this.data.last && this.data.direction === 1) {
    //   this.data.direction = 0;
    // }

    // if (this.data.current < this.data.last && this.data.direction === 0) {
    //   this.data.direction = 1;
    // }

    app.scroll.data.ease = 0.15;
  }

  resize = () => {
    this.bounding = this.scroll.getBoundingClientRect();
  }

  events() {
    window.addEventListener('resize', throttle(this.resize, 66), false);
  }

  position(value) {
    this.data.current = -value;
  }

  on() {
    this.vs.on(this.calc);
    gsap.ticker.add(this.render);
  }

  reset() {
    history.scrollRestoration = 'manual';
  }

  init() {
    this.reset();
    if (!Sniffer.isDevice) {
      this.resize();
      this.events();
      this.on();
    } else {
      gsap.ticker.add(this.render);
    }
    if (app.ipad) {
      this.data.speed = 1.2;
    }
  }
}
