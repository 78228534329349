import { gsap } from 'gsap';
import lottie from 'lottie-web';
import { app, inview } from '../../utils/store';
import json from '../../assets/json/divider.json';

export default class Hello {
  constructor() {
    this.el = document.querySelector('.s-hello');

    this.hello = {
      center: this.el.querySelector('.hello-center svg'),
      graphic: this.el.querySelector('.hello-graphic'),
      divider: this.el.querySelector('.hello-divider'),
      spinner: this.el.querySelector('.hello-spinner'),
      heading: this.el.querySelector('.hello-heading'),
    }

    this.state = {
      inview: false,
    }

    this.rotate = {
      last: 0,
      delta: 0,
      current: 0,
      disabled: false,
    }
  }

  init() {
    gsap.ticker.add(this.render);

    const options = {
      loop: false,
      autoplay: false,
      renderer: 'svg',
      animationData: json,
      container: this.hello.divider,
    };

    this.animation = lottie.loadAnimation(options);
  }

  render = () => {
    if (app.loading.state === true) return;

    this.inview();
    this.velocity();
    this.transform();
  }

  inview = () => {
    if (inview(this.hello.divider)) {
      if (this.state.inview === true) return;
      this.state.inview = true;
      this.animation.play();
    } else {
      if (this.state.inview === false) return;
      this.state.inview = false;
      this.animation.pause();
    }
  }

  reset = () => {
    this.rotate = {
      last: 0,
      delta: 0,
      current: 0,
      disabled: false,
    }

    this.lp = 0;

    this.hello.spinner.style.transform = `rotate(0deg)`;
    this.hello.center.style.transform = `rotate(0deg)`;
  }

  velocity = () => {
    if (this.rotate.disabled) return;
    if (this.lp != null) this.rotate.delta = Math.abs(app.scroll.data.last) - this.lp;
    if (this.rotate.current > 360) this.rotate.current = 0;

    this.lp = Math.abs(app.scroll.data.last);
    this.rotate.current += 1 / 5;
  }

  transform = () => {
    if (this.rotate.disabled) return;
    if (this.rotate.last < -360) this.rotate.last = 0;
    if (this.rotate.last > 360) this.rotate.last = 0;

    this.rotate.last = this.rotate.last + this.rotate.delta / 5;

    this.hello.spinner.style.transform = `rotate(${this.rotate.last + this.rotate.current}deg)`;
    this.hello.center.style.transform = `rotate(${((this.rotate.last + this.rotate.current * 5))}deg)`;
  }

  enter = () => {
    gsap.fromTo(
      this.hello.graphic,
      { yPercent: 10, autoAlpha: 0, scale: 0.2 },
      { yPercent: 0, autoAlpha: 1, scale: 1, duration: 3, delay: 1.5, ease: 'power4.inOut', }
    )

    gsap.fromTo(
      this.hello.graphic,
      { rotate: -360 },
      { rotate: 0, duration: 6, ease: 'power4.inOut', }
    )
  }
}
